import axios from "axios";
import { HOST, API } from "../constants/apiConstants";
import {
  GRID_LIST_REQUEST,
  GRID_LIST_SUCCESS,
  GRID_LIST_FAIL,
  SPEC_LIST_REQUEST,
  SPEC_LIST_SUCCESS,
  SPEC_LIST_FAIL,
  CATALOG_LIST_REQUEST,
  CATALOG_LIST_SUCCESS,
  CATALOG_LIST_FAIL,
} from "../constants/sectionConstants";

export const listGrids = () => async (dispatch) => {
  try {
    dispatch({ type: GRID_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const gridListUrl = `${endpoint}/grids/`;
    const { data } = await axios.get(gridListUrl);

    dispatch({ type: GRID_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GRID_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listSpecs = () => async (dispatch) => {
  try {
    dispatch({ type: SPEC_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const specListUrl = `${endpoint}/specs/`;
    const { data } = await axios.get(specListUrl);

    dispatch({ type: SPEC_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SPEC_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listCatalogs = () => async (dispatch) => {
  try {
    dispatch({ type: CATALOG_LIST_REQUEST });
    const endpoint = `${HOST}${API}`;
    const catalogListUrl = `${endpoint}/catalogs/`;
    const { data } = await axios.get(catalogListUrl);

    dispatch({ type: CATALOG_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CATALOG_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
